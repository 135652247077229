@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

$scrollbar-thumb-color: #358bd2;
$scrollbar-track-color: #ccffe7;

body {
    margin: 0;
    min-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

// Scrollbar
*::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-corner {
    background: #dfdfdf;
}

*::-webkit-scrollbar-track {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='2' height='2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 0H0v1h1v1h1V1H1V0z' fill='silver'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2 0H1v1H0v1h1V1h1V0z' fill='%23fff'/%3E%3C/svg%3E");
}

*::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #fff, inset -2px -2px grey, inset 2px 2px #dfdfdf;
}
